//    ***** CSS IMPORTS *******
import './assets/css/index.scss';
import "react-toastify/dist/ReactToastify.css";
import 'cropperjs/src/css/cropper.scss';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

//    ***** REST OF IMPORTS *******
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import setUpJQueryExtensions from "./core/services/extensions/jquery-extensions";
import setUpExtensions from "./core/services/extensions/window-extensions";
import Utils from "./core/services/utils";
import EnvService from "./core/services/env-service";
import KeyboardShortcutsService from "./core/services/keyboard-shortcuts";
import {registerPlugin} from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import Sortable, {MultiDrag} from "sortablejs";


export const RootElement = document.getElementById('root')!;
export const ReactRoot = ReactDOM.createRoot(RootElement);

/**
 * The driver of the application.
 */
class Driver {

    /**
     * Runs the application.
     */
    public static run(): void {
        Utils.setAppInfo({title: EnvService.title, description: EnvService.description});
        KeyboardShortcutsService.listenForKeyPresses(RootElement);
        this.mountSortablePlugins();
        this.setExtensions();
        this.registerPlugins();
        void this.initializeScrollbar();
        void this.initializeClassFlags();
        this.render();
        this.reportWebVitals();
    }

    /**
     * Sets the extensions.
     */
    private static setExtensions(): void {
        setUpJQueryExtensions();
        setUpExtensions();
    }

    /**
     * Registers the plugins.
     */
    private static registerPlugins() {
        registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
    }

    /**
     * Renders the application.
     * @private
     */
    private static render(): void {
        // STRICT MODE WILL INTENTIONALLY CALL USE_REDUCER AND USE_STATE's INITIAL METHODS MULTIPLE TIMES TO TEST THE PURIDITY OF SUCH
        //  FUNCTIONS. IT ALSO CHECKS FOR THE PROBLEMS IN CONCURRENT MODE SO THE PERFORMANCE IS INTENTIONALLY WORSE. PLEASE BE AWARE!!!
        // IF A DISPATCH OF A USE REDUCER THROWS, THE DISPATCHER AUTOMATICALLY RECALLS THE DISPATCH IN HOPE THAT THE ERROR IS RESOLVED
        ReactRoot.render(
            // <StrictMode>
            <App/>
            // </StrictMode>
        );
    }

    /**
     * Initializes whether we need to force the webkit scrollbar or not.
     * @private
     */
    private static async initializeScrollbar() {
        await new Promise((resolve) => document.addEventListener('DOMContentLoaded', resolve));
        // Exclude Edge which also includes 'Chrome' in UA
        const isChrome: boolean = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);
        const chromeVersionArray: RegExpExecArray | null = /Chrome\/([0-9]+)/.exec(navigator.userAgent);
        const chromeVersion: number = chromeVersionArray ? parseInt(chromeVersionArray[1], 10) : 0;
        const isModernChrome: boolean = isChrome && chromeVersion >= 121;
        document.body.classList.toggle('force-webkit-scrollbar', isModernChrome);
    }

    /**
     * Initializes the class flags.
     * @private
     */
    private static async initializeClassFlags() {
        await new Promise((resolve) => document.addEventListener('DOMContentLoaded', resolve));
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
        document.body.classList.toggle("ios-device", isIOS);

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        document.body.classList.toggle("safari-browser", isSafari);

        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        document.body.classList.toggle("mobile-device", isMobile);
    }

    /**
     * Reports the web vitals.
     * @private
     */
    private static reportWebVitals(): void {
        reportWebVitals();
    }

    /**
     * Mounts the sortable plugins.
     * @private
     */
    private static mountSortablePlugins() {
        Sortable.mount(new MultiDrag());
    }
}

Driver.run();


