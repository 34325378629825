/**
 * Different values of api call methods.
 */
export enum ApiMethods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
}

/**
 * Different possible entries found in the [window.navigator.platform] value.
 */
export enum OperatingSystems {
    mac = 'Mac',
    ios = 'IOS',
    windows = 'Windows',
    android = 'Android',
    linux = 'Linux',
}

/**
 * Different types of file types the file-fetcher of browser accepts
 */
export enum InputFileAcceptTypes {
    png = '.png',
    jpg = '.jpg',
    jpeg = '.jpeg',
    mp4 = '.mp4',
    mov = '.mov',
    webm = '.webm',
    flv = '.flv',
    wmv = '.wmv',
    pdf = '.pdf',
    json = '.json',
}

/**
 * Options for reading a file.
 */
export enum FileReadingOptions {
    binaryString = 'binaryString',
    dataUrl = 'dataUrl',
    text = 'text',
    arrayBuffer = 'arrayBuffer',
    json = 'json',
}

/**
 * Different input types for properties that are used to display different controllers in the properties sidebar
 */
export enum PropertyInputTypes {
    // show number input
    number = 'number',
    // show text input
    text = 'text',
    // show select input.
    selection = 'selection',
    // show color input
    color = 'color',
    // show a checkbox
    checkbox = 'checkbox',
    // show a single button with an icon
    // show toggle buttons but with icons
    iconGroup = 'iconGroup',
    // show nothing
    imageManipulation = 'imageManipulation',
    uploadImage = 'uploadImage',
    none = 'none',
}

/**
 * The available units of the properties.
 */
export enum PropertyUnits {
    px = 'px',
    mm = 'mm',
    percent = '%',
    deg = 'deg',
}

/**
 * The available layers of the template element groups.
 */
export enum TemplateElementsGroupsLayers {
    uiSpecific = 'uiSpecific',
    applicationSpecific = 'applicationSpecific',
    pageSpecific = 'pageSpecific',
}

/**
 * Different property types that are used in different models. Each of these properties will create the inputs in
 * properties sidebar if their item is clicked.
 */
export enum PropertyTypes {
    css = 'css',
    attribute = 'attributes',
    none = 'none',
}

/**
 * Different repeat types of a collection UI Entities.
 */
export enum RepeatTypes {
    multiple = 'multiple',
    infinite = 'infinite',
}

/**
 * Different types of caning a page scale in template.
 */
export enum ChangePageScaleTypes {
    add = 'add',
    multiply = 'multiply',
}

/**
 * The types of property input callbacks
 */
export enum CallbackTypes {
    onBlur = 'onBlur',
    onChange = 'onChange',
    internal = 'internal',
}

/**
 * The modes of exporting a UI-Entity.
 */
export enum UIEntityExportModes {
    json,
    string,
    entity,
    entityWithoutIds,
}

/**
 * The available types of the template elements that the Server accepts.
 */
export enum ServerSideElementTypes {
    text = "Text",
    image = "Image",
    table = "Table",
    identifier = "Identifier",
    grid = "Grid",
    container = "Object",
    averyLabel = "Avery",
    barcode = "Barcode",
    html = 'HTML',
}

/**
 * The available type of the elements that exist in the application.
 */
export enum ElementTypes {
    text = 'Text',
    time = 'time',
    pageInfo = 'pageInfo',
    image = 'Image',
    grid = 'Grid',
    table = 'Table',
    container = 'Object',
    averyLabel = 'Avery',
    barcode = 'Barcode',
    html = 'HTML',
    line = 'Line',
    containerBorder = "Container's Border",
    identifier = 'Identifier',
}

/**
 * Types of the container border Elements.
 */
export enum ContainerBorderElementTypes {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left',
}

/**
 * The indicator of the property name when the size position changes.
 */
export enum ElementSizeChangeType {
    width = 'width',
    height = 'height',
}

/**
 * The indicator of the property name when the element position changes.
 */
export enum ElementPositionChangeType {
    top = 'top',
    left = 'left',
}

/**
 * The key values for the Keyboard events.
 *
 * * These keys are supported in Mac,Linux, Windows, and Android, and therefore, some keys are intentionally left behind since not all the
 * platforms would support them
 * * The normal keyboard keys are not included in here as each language would have a different key value.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 */
export enum KeyboardEventKeys {
    // Modifier Keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#modifier_keys
    alt = 'Alt',
    capsLock = "CapsLock",
    control = "Control",
    meta = 'Meta',
    shift = 'Shift',

    // Whitespace keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#modifier_keys
    enter = 'Enter',
    tab = 'Tab',
    space = ' ',

    // Navigation keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#navigation_keys
    arrowDown = 'ArrowDown',
    arrowLeft = 'ArrowLeft',
    arrowRight = 'ArrowRight',
    arrowUp = 'ArrowUp',
    end = 'End',
    home = 'Home',
    pageDown = 'PageDown',
    pageUp = 'PageUp',

    // Editing keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#editing_keys
    backspace = 'Backspace',
    clear = 'Clear',
    delete = 'Delete',
    // this one's specific for IE 9 and Firefox 36 and lower.
    deleteOld = 'Del',

    // UI Keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#ui_keys
    contextMenu = 'ContextMenu',
    escape = 'Escape',
    help = 'Help',

    // Function keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#function_keys
    f1 = "F1",
    f2 = "F2",
    f3 = "F3",
    f4 = "F4",
    f5 = "F5",
    f6 = "F6",
    f7 = "F7",
    f8 = "F8",
    f9 = "F9",
    f10 = "F10",
    f11 = "F11",
    f12 = "F12",

    // Numeric Keypad Keys
    // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#numeric_keypad_keys
    decimalOld = "Decimal",
    decimal = ".",
    multiplyOld = "Multiply",
    multiply = "*",
    addOld = "Add",
    add = "+",
    divideOld = "Divide",
    divide = "/",
    subtractOld = "Subtract",
    subtract = "-",
    separatorOld = "Separator",
    separator = ",",
    zero = "0",
    one = "1",
    two = "2",
    three = "3",
    four = "4",
    five = "5",
    six = "6",
    seven = "7",
    eight = "8",
    nine = "9",
}

/**
 * Types of the application toasts.
 */
export enum ToastTypes {
    importTemplateError = 'importTemplateError',
    setPageSizeError = 'setPageSizeError',
    addTemplateElementError = 'addTemplateElementError',
    elementConnectionFailed = 'elementConnectionFailed',
    printingPageUnavailable = 'printingPageUnavailable',
    apiResponseError = 'apiResponseError',
    apiResponseSuccess = 'apiResponseSuccess',
}
